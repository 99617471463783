import Venue from './Venue'
import Wine from './Wine'
import BottleSize from './BottleSize'

export default class VenueWine {
	constructor() {
		this.id = null
		this.title = null
		this.purchasePrices = []
		this.venue = new Venue()
		this.sellingPrice = null
		this.glassPrice = null
		this.amount = 0
		this.comments = null
		this.wine = new Wine()
		this.shelfNumber = null
		this.storable = null
		this.glassMode = false
		this.amountGlasses = 0
		this.favorite = false
		this.year = null
		this.bottleSize = new BottleSize()
		this.purchasePriceAvg = 0
		this.rarity = false
		this.articleNumber = null
		this.deleted = false
		this.ratings = []
		this.cashNote = null
	}
}