export default class InventoryVenueWine{
    constructor() {
        this.id = null;
        this.actualAmount = null;
        this.venueWine = null;
        this.inventoryVenueWineState = null;
        this.sellingPrice = null;
        this.targetAmount = null;
        this.duplicate = null;
    }
}