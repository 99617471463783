import router from '../router/index.js'
import Region from '../model/Region'
import Land from '../model/Land'
import Winery from '../model/Winery'
import Wine from '../model/Wine'
import Classification from '../model/Classification'
import GrapeVariety from '../model/GrapeVariety'
import WineType from '../model/WineType'
import VenueWine from '../model/VenueWine'
import PurchasePrice from '../model/PurchasePrice'
import Rating from '../model/Rating'
import RatingAgency from '../model/RatingAgency'
import WineSupplier from '../model/WineSupplier'
import Venue from '../model/Venue'
import BottleSize from '../model/BottleSize'
import inventoryUser from '../model/inventoryUser'
import usersPermissionsUser from '../model/usersPermissionsUser'
import Inventory from '../model/Inventory'
import InventoryVenueWine from '../model/InventoryVenueWine'

export default {
	/**
	 * Map response of GetAllLands to localized options for a select input
	 * @param {*} response 
	 * @returns land options for a select input
	 */
	mapGetAllLandsResponseToOptions(response) {
		const landOptions = response['data'].data.map(land => {
			return {
				text: router.app.$t('countries.' + land.attributes.localeKey),
				value: land.id,
				regions: this.mapRegionArrayApiToRegionArrayUi(land.attributes.regions),
			}
		})
		return landOptions
	},

	/**
	 * Returns only general wineries (without venue-specific entries)
	 * 
	 * @param {*} response 
	 * @returns A list of Wineries
	 */
	mapSearchWineriesResponse(response) {
		var wineries = response['data'].data.filter((winery) => winery.attributes.venue.data === null)

		const wineriesUiList = wineries.map((winery) => {
			const wineryModel = new Winery()
			wineryModel.id = winery.id
			wineryModel.title = winery.attributes.title
			wineryModel.land = this.mapLandWithGrapeVarietiesToLandUi(winery.attributes.land)
			wineryModel.region = this.mapRegionApiToRegionUi(winery.attributes.region)
			wineryModel.wines = this.mapWineArrayApiToWineArrayUi(winery.attributes.wines)
			return wineryModel
		})

		return wineriesUiList
	},
	mapLandWithGrapeVarietiesToLandUi(landApiModel) {
		const landUiModel = new Land()
		if (landApiModel === undefined || landApiModel.data === null) {
			return landUiModel
		}
		landUiModel.id = landApiModel.data.id
		landUiModel.title = landApiModel.data.attributes.title
		landUiModel.localeKey = landApiModel.data.attributes.localeKey
		landUiModel.grapeVarieties = this.mapGrapeVarietyArrayWithoutWinetypeApiToGrapeVarietyArrayUi(landApiModel.data.attributes.grape_varieties)
		return landUiModel
	},
	mapGrapeVarietyArrayWithoutWinetypeApiToGrapeVarietyArrayUi(grapeVarietyArrayApiModel) {
		if (grapeVarietyArrayApiModel === undefined) {
			return []
		}
		const grapeVarietyArrayUiModel = grapeVarietyArrayApiModel.data.map((grapeVarietyApiModel) => {
			const grapeVarietyUiModel = new GrapeVariety()
			grapeVarietyUiModel.id = grapeVarietyApiModel.id
			grapeVarietyUiModel.title = grapeVarietyApiModel.attributes.title
			grapeVarietyUiModel.curated = grapeVarietyApiModel.attributes.curated
			grapeVarietyUiModel.lands = this.mapLandArrayApiToLandArrayUi(grapeVarietyApiModel.attributes.lands)
			return grapeVarietyUiModel
		})
		return grapeVarietyArrayUiModel
	},

	mapFindWineryResponse(response) {
		const wineryData = response['data'].data

		const wineries = wineryData.map((winery) => {
			const wineryModel = new Winery()
			wineryModel.id = winery.id
			wineryModel.title = winery.attributes.title
			wineryModel.land = this.mapLandApiToLandUi(winery.attributes.land)
			wineryModel.region = this.mapRegionApiToRegionUi(winery.attributes.region)
			wineryModel.venue = this.mapVenueApiToVenueUi(winery.attributes.venue)

			return wineryModel
		})

		return wineries
	},

	mapGetInventoryUsersResponse(response) {
		const inventoryUsersData = response['data'].data
		const inventoryUsers = inventoryUsersData.map((user) => {
			const inventoryUserUiModel = new inventoryUser()
			inventoryUserUiModel.id = user.id
			inventoryUserUiModel.state = user.attributes.state
			inventoryUserUiModel.users_permissions_user = this.mapUsersPermissionsUserApiToUsersPermissionsUserUi(user.attributes.users_permissions_user)
			return inventoryUserUiModel
		})
		return inventoryUsers

	},
	mapUsersPermissionsUserApiToUsersPermissionsUserUi(usersPermissionsUserApiModel) {
    if (usersPermissionsUserApiModel == null) {
      return {}
    }
		const usersPermissionsUserUiModel = new usersPermissionsUser()
    if (usersPermissionsUserApiModel.data == null) {
      usersPermissionsUserUiModel.id = usersPermissionsUserApiModel.id
      usersPermissionsUserUiModel.username = usersPermissionsUserApiModel.user
      usersPermissionsUserUiModel.email = usersPermissionsUserApiModel.email
      usersPermissionsUserUiModel.provider = usersPermissionsUserApiModel.provider
    } else {
      usersPermissionsUserUiModel.id = usersPermissionsUserApiModel.data.id
      usersPermissionsUserUiModel.username = usersPermissionsUserApiModel.data.attributes.username
      usersPermissionsUserUiModel.email = usersPermissionsUserApiModel.data.attributes.email
      usersPermissionsUserUiModel.provider = usersPermissionsUserApiModel.data.attributes.provider
    }

		return usersPermissionsUserUiModel
	},
	mapGetInventoriesResponse(response) {
		const inventoriesData = response['data'].data
		const inventories = inventoriesData.map((inventory) => {
			const inventoryUiModel = this.mapGetInventoryResponse(inventory)
			return inventoryUiModel
		})
		return inventories
	},

  mapGetInventoryResponse(response) {
		const inventoriesData = response
    const inventoryUiModel = new Inventory()
    inventoryUiModel.id = inventoriesData.id
    inventoryUiModel.name = inventoriesData.attributes.name
    inventoryUiModel.venue = inventoriesData.attributes.venue
    inventoryUiModel.inventoryType = inventoriesData.attributes.inventoryType
    inventoryUiModel.startDate = inventoriesData.attributes.startDate
    inventoryUiModel.transferDate = inventoriesData.attributes.transferDate
    inventoryUiModel.inventoryState = inventoriesData.attributes.inventoryState
		inventoryUiModel.inventory_users = this.mapInventoryUsersArrayApiToInventoryUsersArrayUi(inventoriesData.attributes.inventory_users)
    inventoryUiModel.inventoryScope = inventoriesData.attributes.inventoryScope
    if (inventoriesData.attributes.inventoryVenueWines) {
      inventoryUiModel.inventoryVenueWines = this.mapInventoryVenueWinesArrayApiToInventoryVenueWinesArrayUi(inventoriesData.attributes.inventoryVenueWines)
    }
    return inventoryUiModel
		

	},

	mapInventoryUsersArrayApiToInventoryUsersArrayUi(inventoryUsersArrayApiModel) {
		if (inventoryUsersArrayApiModel === undefined) {
			return []
		}
		const inventoryUsersArrayUiModel = inventoryUsersArrayApiModel.data.map((inventoryUserApiModel) => {
			const inventoryUserUiModel = new inventoryUser()
			inventoryUserUiModel.id = inventoryUserApiModel.id
			inventoryUserUiModel.state = inventoryUserApiModel.attributes.state
			inventoryUserUiModel.users_permissions_user = this.mapUsersPermissionsUserApiToUsersPermissionsUserUi(inventoryUserApiModel.attributes.users_permissions_user)
			return inventoryUserUiModel
		})
		return inventoryUsersArrayUiModel
	},


  mapInventoryVenueWinesArrayApiToInventoryVenueWinesArrayUi(inventoryVenueWinesArrayApiModel) {
    if (inventoryVenueWinesArrayApiModel === undefined) {
      return []
    }
    
    inventoryVenueWinesArrayApiModel = inventoryVenueWinesArrayApiModel.data.map((inventoryVenueWineApiModel) => {
      const inventoryVenueWineUiModel = new InventoryVenueWine()
     
      inventoryVenueWineUiModel.id = inventoryVenueWineApiModel.id
      inventoryVenueWineUiModel.actualAmount= inventoryVenueWineApiModel.attributes.actualAmount
      inventoryVenueWineUiModel.venueWine= this.mapVenueWineApiToVenueWineUi({data: inventoryVenueWineApiModel.attributes.venue_wine})
      inventoryVenueWineUiModel.inventoryVenueWineState= inventoryVenueWineApiModel.attributes.inventoryVenueWineState
      inventoryVenueWineUiModel.sellingPrice= inventoryVenueWineApiModel.attributes.sellingPrice
      inventoryVenueWineUiModel.targetAmount= inventoryVenueWineApiModel.attributes.targetAmount
			inventoryVenueWineUiModel.shelf = inventoryVenueWineApiModel.attributes.shelf
      inventoryVenueWineUiModel.purchasePrice = inventoryVenueWineApiModel.attributes.purchasePrice
      inventoryVenueWineUiModel.canUpdatePurchasePrice = inventoryVenueWineApiModel.attributes.canUpdatePurchasePrice
			inventoryVenueWineUiModel.duplicate = inventoryVenueWineApiModel.attributes.duplicate

      return inventoryVenueWineUiModel
    })
    return inventoryVenueWinesArrayApiModel
  },



	mapGetWineryResponse(response) {
		return this.mapWineryApiToWineryUi(response['data'])
	},

	mapPostWineryResponse(response) {
		return this.mapWineryApiToWineryUi(response['data'])
	},

	mapGetAllWineTypesResponseToOptions(response) {
		const wineTypeOptions = response['data'].data.map(wineType => {
			return {
				text: router.app.$t('wineTypes.' + wineType.attributes.localeKey),
				value: wineType.id,
				grapeVarieties: this.mapGrapeVarietyArrayApiToGrapeVarietyArrayUi(wineType.attributes.grape_varieties),
			}
		})
		return wineTypeOptions
	},

	mapFindWinesResponse(response) {
		return this.mapWineArrayApiToWineArrayUi(response['data'])
	},

	mapPostWineResponse(response) {
		return this.mapWineApiToWineUi(response['data'])
	},


	/*----------- Basic Strapi Model Mappings --------------- */

	mapWineryApiToWineryUi(wineryApiModel) {
		const wineryUiModel = new Winery()
		if (wineryApiModel === undefined || wineryApiModel.data === null) {
			return wineryUiModel
		}
		wineryUiModel.id = wineryApiModel.data.id
		wineryUiModel.title = wineryApiModel.data.attributes.title
		wineryUiModel.address = wineryApiModel.data.attributes.address
		wineryUiModel.image = wineryApiModel.data.attributes.image
		wineryUiModel.region = this.mapRegionApiToRegionUi(wineryApiModel.data.attributes.region)
		wineryUiModel.land = this.mapLandApiToLandUi(wineryApiModel.data.attributes.land)
		wineryUiModel.wines = this.mapWineArrayApiToWineArrayUi(wineryApiModel.data.attributes.wines)
		return wineryUiModel
	},
	/**
	 * Maps winery ui model to post model that can be sent to the api
	 * @param {*} wineryUiModel 
	 * @returns 
	 */
	mapWineryUiToWineryPostModel(wineryUiModel) {
		const wineryPostModel = {
			data: {
				title: wineryUiModel.title,
				region: wineryUiModel.region.id,
				land: wineryUiModel.land.id,
			}
		}
		return wineryPostModel
	},

	mapRegionApiToRegionUi(regionApiModel) {
		const regionUiModel = new Region()
		if (regionApiModel === undefined || regionApiModel.data === null) {
			return regionUiModel
		}
		regionUiModel.id = regionApiModel.data.id
		regionUiModel.title = regionApiModel.data.attributes.title
		regionUiModel.localeKey = regionApiModel.data.attributes.localeKey
		regionUiModel.curated = regionApiModel.data.attributes.curated
		return regionUiModel
	},
	mapRegionArrayApiToRegionArrayUi(regionArrayApiModel) {
		if (regionArrayApiModel === undefined) {
			return []
		}
		const regionArrayUiModel = regionArrayApiModel.data.map((regionApiModel) => {
			const regionUiModel = new Region()
			regionUiModel.id = regionApiModel.id
			regionUiModel.title = regionApiModel.attributes.title
			regionUiModel.localeKey = regionApiModel.attributes.localeKey
			regionUiModel.curated = regionApiModel.attributes.curated
			return regionUiModel
		})
		return regionArrayUiModel
	},
	mapRegionUiToRegionApi(regionUiModel) {
		const regionApiModel = {
			data: {
				id: regionUiModel.id,
				attributes: {
					title: regionUiModel.title,
					localeKey: regionUiModel.localeKey,
					curated: regionUiModel.curated
				}
			}
		}
		return regionApiModel
	},

	mapLandApiToLandUi(landApiModel) {
		const landUiModel = new Land()
		if (landApiModel === undefined || landApiModel.data === null) {
			return landUiModel
		}
		landUiModel.id = landApiModel.data.id
		landUiModel.title = landApiModel.data.attributes.title
		landUiModel.localeKey = landApiModel.data.attributes.localeKey
		landUiModel.grapeVarieties = this.mapGrapeVarietyArrayApiToGrapeVarietyArrayUi(landApiModel.data.attributes.grape_varieties)

		return landUiModel
	},
	mapLandArrayApiToLandArrayUi(landArrayApiModel) {
		if (landArrayApiModel === undefined) {
			return []
		}
		const landArrayUiModel = landArrayApiModel.data.map((landApiModel) => {
			const landUiModel = new Land()
			landUiModel.id = landApiModel.id
			landUiModel.title = landApiModel.attributes.title
			landUiModel.localeKey = landApiModel.attributes.localeKey
			return landUiModel
		})
		return landArrayUiModel
	},
	mapLandUiToLandApi(landUiModel) {
		const landApiModel = {
			data: {
				id: landUiModel.id,
				attributes: {
					title: landUiModel.title,
					localeKey: landUiModel.localeKey
				}
			}
		}
		return landApiModel
	},

	mapClassificationApiToClassificationUi(classificationApiModel) {
		const classificationUiModel = new Classification()
		if (classificationApiModel === undefined || classificationApiModel.data === null) {
			return classificationUiModel
		}
		classificationUiModel.id = classificationApiModel.data.id
		classificationUiModel.title = classificationApiModel.data.attributes.title
		return classificationUiModel
	},
	mapClassificationUiToClassificationApi(classificationUiModel) {
		const classificationApiModel = {
			data: {
				id: classificationUiModel.id,
				attributes: {
					title: classificationUiModel.title,
				}
			}
		}
		return classificationApiModel
	},

	mapGrapeVarietyApiToGrapeVarietyUi(grapeVarietyApiModel) {
		const grapeVarietyUiModel = new GrapeVariety()
		if (grapeVarietyApiModel === undefined || grapeVarietyApiModel.data === null) {
			return grapeVarietyUiModel
		}
		grapeVarietyUiModel.id = grapeVarietyApiModel.data.id
		grapeVarietyUiModel.title = grapeVarietyApiModel.data.attributes.title
		grapeVarietyUiModel.wineTypes = this.mapWineTypeArrayApiToWineTypeArrayUi(grapeVarietyApiModel.data.attributes.wine_types)
		grapeVarietyUiModel.curated = grapeVarietyApiModel.data.attributes.curated
		grapeVarietyUiModel.lands = this.mapLandArrayApiToLandArrayUi(grapeVarietyApiModel.data.attributes.lands)
		return grapeVarietyUiModel
	},
	mapGrapeVarietyArrayApiToGrapeVarietyArrayUi(grapeVarietyArrayApiModel) {
		if (grapeVarietyArrayApiModel === undefined) {
			return []
		}
		const grapeVarietyArrayUiModel = grapeVarietyArrayApiModel.data.map((grapeVarietyApiModel) => {
			const grapeVarietyUiModel = new GrapeVariety()
			grapeVarietyUiModel.id = grapeVarietyApiModel.id
			grapeVarietyUiModel.title = grapeVarietyApiModel.attributes.title
			grapeVarietyUiModel.wineTypes = this.mapWineTypeArrayApiToWineTypeArrayUi(grapeVarietyApiModel.attributes.wine_types)
			grapeVarietyUiModel.curated = grapeVarietyApiModel.attributes.curated
			grapeVarietyUiModel.lands = this.mapLandArrayApiToLandArrayUi(grapeVarietyApiModel.attributes.lands)
			return grapeVarietyUiModel
		})
		return grapeVarietyArrayUiModel
	},
	mapGrapeVarietyUiToGrapeVarietyApi(grapeVarietyUiModel) {
		const grapeVarietyApiModel = {
			data: {
				id: grapeVarietyUiModel.id,
				attributes: {
					title: grapeVarietyUiModel.title,
				}
			}
		}
		return grapeVarietyApiModel
	},

	mapWineTypeApiToWineTypeUi(wineTypeApiModel) {
		const wineTypeUiModel = new WineType()
		if (wineTypeApiModel === undefined || wineTypeApiModel.data === null) {
			return wineTypeUiModel
		}
		wineTypeUiModel.id = wineTypeApiModel.data.id
		wineTypeUiModel.title = wineTypeApiModel.data.attributes.title
		wineTypeUiModel.localeKey = wineTypeApiModel.data.attributes.localeKey
		wineTypeUiModel.grapeVarieties = this.mapGrapeVarietyArrayWithoutWinetypeApiToGrapeVarietyArrayUi(wineTypeApiModel.data.attributes.grapeVarieties)
		return wineTypeUiModel
	},
	mapWineTypeArrayApiToWineTypeArrayUi(wineTypeArrayApiModel) {
		if (wineTypeArrayApiModel === undefined) {
			return []
		}
		const wineTypeArrayUiModel = wineTypeArrayApiModel.data.map((wineTypeApiModel) => {
			const wineTypeUiModel = new WineType()
			wineTypeUiModel.id = wineTypeApiModel.id
			wineTypeUiModel.title = wineTypeApiModel.attributes.title
			wineTypeUiModel.localeKey = wineTypeApiModel.attributes.localeKey
			wineTypeUiModel.grapeVarieties = this.mapGrapeVarietyArrayApiToGrapeVarietyArrayUi(wineTypeApiModel.data.attributes.grapeVarieties)
			return wineTypeUiModel
		})
		return wineTypeArrayUiModel
	},

	mapWineApiToWineUi(wineApiModel) {
		const wineUiModel = new Wine()
		if (wineApiModel === undefined || wineApiModel.data === null) {
			return wineUiModel
		}
		wineUiModel.id = wineApiModel.data.id
		wineUiModel.title = wineApiModel.data.attributes.title
		wineUiModel.region = this.mapRegionApiToRegionUi(wineApiModel.data.attributes.region)
		wineUiModel.venue = this.mapVenueApiToVenueUi(wineApiModel.data.attributes.venue)
		wineUiModel.articleNr = wineApiModel.data.attributes.articleNr
		wineUiModel.winery = this.mapWineryApiToWineryUi(wineApiModel.data.attributes.winery)
		wineUiModel.classification = this.mapClassificationApiToClassificationUi(wineApiModel.data.attributes.classification)
		wineUiModel.grapeVariety = this.mapGrapeVarietyApiToGrapeVarietyUi(wineApiModel.data.attributes.grapeVariety)
		wineUiModel.land = this.mapLandApiToLandUi(wineApiModel.data.attributes.land)
		wineUiModel.wineType = this.mapWineTypeApiToWineTypeUi(wineApiModel.data.attributes.wineType)
		wineUiModel.image = wineApiModel.data.attributes.image
		return wineUiModel
	},
	mapWineArrayApiToWineArrayUi(wineArrayApiModel) {
		if (wineArrayApiModel === undefined) {
			return []
		}
		const wineArrayUiModel = wineArrayApiModel.data.map((wineApiModel) => {
			const wineUiModel = new Wine()
			wineUiModel.id = wineApiModel.id
			wineUiModel.title = wineApiModel.attributes.title
			wineUiModel.region = this.mapRegionApiToRegionUi(wineApiModel.attributes.region)
			wineUiModel.venue = this.mapVenueApiToVenueUi(wineApiModel.attributes.venue)
			wineUiModel.articleNr = wineApiModel.attributes.articleNr
			wineUiModel.winery = this.mapWineryApiToWineryUi(wineApiModel.attributes.winery)
			wineUiModel.classification = this.mapClassificationApiToClassificationUi(wineApiModel.attributes.classification)
			wineUiModel.grapeVariety = this.mapGrapeVarietyApiToGrapeVarietyUi(wineApiModel.attributes.grapeVariety)
			wineUiModel.land = this.mapLandApiToLandUi(wineApiModel.attributes.land)
			wineUiModel.wineType = this.mapWineTypeApiToWineTypeUi(wineApiModel.attributes.wineType)
			wineUiModel.image = wineApiModel.attributes.image
			return wineUiModel
		})
		return wineArrayUiModel
	},

	mapVenueWineApiToVenueWineUi(venueWineApiModel) {
		venueWineApiModel = venueWineApiModel.data
		const venueWineUiModel = new VenueWine()
		if (venueWineApiModel === undefined || venueWineApiModel.data === null) {
			return venueWineUiModel
		}
		venueWineUiModel.id = venueWineApiModel.data.id
		venueWineUiModel.title = venueWineApiModel.data.attributes.title
		venueWineUiModel.purchasePrices = this.mapPurchasePricesArrayApiToPurchasePricesArrayUi(venueWineApiModel.data.attributes.purchasePrices)
		venueWineUiModel.venue = this.mapVenueApiToVenueUi(venueWineApiModel.data.attributes.venue)
		venueWineUiModel.sellingPrice = venueWineApiModel.data.attributes.sellingPrice
		venueWineUiModel.glassPrice = venueWineApiModel.data.attributes.glassPrice
		venueWineUiModel.amount = venueWineApiModel.data.attributes.amount
		venueWineUiModel.comments = venueWineApiModel.data.attributes.comments
		venueWineUiModel.wine = this.mapWineApiToWineUi(venueWineApiModel.data.attributes.wine)
		venueWineUiModel.shelfNumber = venueWineApiModel.data.attributes.shelfNumber
		venueWineUiModel.storable = venueWineApiModel.data.attributes.storable
		venueWineUiModel.glassMode = venueWineApiModel.data.attributes.glassMode
		venueWineUiModel.amountGlasses = venueWineApiModel.data.attributes.amountGlasses
		venueWineUiModel.favorite = venueWineApiModel.data.attributes.favorite
		venueWineUiModel.year = venueWineApiModel.data.attributes.year
		venueWineUiModel.bottleSize = this.mapBottleSizeApiToBottleSizeUi(venueWineApiModel.data.attributes.bottleSize)
		venueWineUiModel.purchasePriceAvg = venueWineApiModel.data.attributes.purchasePriceAvg
		venueWineUiModel.rarity = venueWineApiModel.data.attributes.rarity
		venueWineUiModel.articleNumber = venueWineApiModel.data.attributes.articleNumber
		venueWineUiModel.deleted = venueWineApiModel.data.attributes.deleted
		venueWineUiModel.ratings = this.mapRatingsArrayApiToRatingsArrayUi(venueWineApiModel.data.attributes.ratings)
		venueWineUiModel.cashNote = venueWineApiModel.data.attributes.cashNote
		return venueWineUiModel
	},

	mapPurchasePriceApiToPurchasePriceUi(purchasePriceApiModel) {
		const purchasePriceUiModel = new PurchasePrice()
		if (purchasePriceApiModel === undefined || purchasePriceApiModel.data === null) {
			return purchasePriceUiModel
		}
		purchasePriceUiModel.id = purchasePriceApiModel.id
		purchasePriceUiModel.price = purchasePriceApiModel.price
		purchasePriceUiModel.wineSupplier = this.mapWineSupplierApiToWineSupplierUi(purchasePriceApiModel.wineSupplier)
		return purchasePriceUiModel
	},
	mapPurchasePricesArrayApiToPurchasePricesArrayUi(purchasePricesArrayApiModel) {
		if (purchasePricesArrayApiModel === undefined) {
			return []
		}
		const purchasePricesArrayUiModel = purchasePricesArrayApiModel.map((purchasePriceApiModel) => {
			return this.mapPurchasePriceApiToPurchasePriceUi(purchasePriceApiModel)
		})
		return purchasePricesArrayUiModel
	},

	mapWineSupplierApiToWineSupplierUi(wineSupplierApiModel) {
		const wineSupplierUiModel = new WineSupplier()
		if (wineSupplierApiModel === undefined || wineSupplierApiModel.data === null) {
			return wineSupplierUiModel
		}
		wineSupplierUiModel.id = wineSupplierApiModel.data.id
		wineSupplierUiModel.title = wineSupplierApiModel.data.attributes.title
		wineSupplierUiModel.phone = wineSupplierApiModel.data.attributes.phone
		wineSupplierUiModel.email = wineSupplierApiModel.data.attributes.email
		wineSupplierUiModel.notes = wineSupplierApiModel.data.attributes.notes
		wineSupplierUiModel.isGeneral = wineSupplierApiModel.data.attributes.isGeneral
		//TODO map venues?

		return wineSupplierUiModel
	},

	mapVenueApiToVenueUi(venueApiModel) {
		const venueUiModel = new Venue()
		if (venueApiModel === undefined || venueApiModel.data === null) {
			return venueUiModel
		}
		venueUiModel.id = venueApiModel.data.id
		venueUiModel.title = venueApiModel.data.attributes.title
		venueUiModel.revenueOptimizePercentLimit = venueApiModel.data.attributes.revenueOptimizePercentLimit
		//todo map missing attributes
		return venueUiModel
	},

	mapBottleSizeApiToBottleSizeUi(bottleSizeApiModel) {
		const bottleSizeUiModel = new BottleSize()
		if (bottleSizeApiModel === undefined || bottleSizeApiModel.data === null) {
			return bottleSizeUiModel
		}
		bottleSizeUiModel.id = bottleSizeApiModel.data.id
		bottleSizeUiModel.title = bottleSizeApiModel.data.attributes.title
		bottleSizeUiModel.amount = bottleSizeApiModel.data.attributes.amount
		return bottleSizeUiModel
	},

	mapRatingApiToRatingUi(ratingApiModel) {
		const ratingUiModel = new Rating()
		if (ratingApiModel === undefined || ratingApiModel.data === null) {
			return ratingUiModel
		}
		ratingUiModel.id = ratingApiModel.id
		ratingUiModel.value = ratingApiModel.value
		ratingUiModel.ratingAgency = this.mapRatingAgencyApiToRatingAgencyUi(ratingApiModel.ratingAgency)
		return ratingUiModel
	},
	mapRatingsArrayApiToRatingsArrayUi(ratingsArrayApiModel) {
		if (ratingsArrayApiModel === undefined) {
			return []
		}
		const ratingsArrayUiModel = ratingsArrayApiModel.map((ratingApiModel) => {
			return this.mapRatingApiToRatingUi(ratingApiModel)
		})
		return ratingsArrayUiModel
	},
	mapRatingAgencyApiToRatingAgencyUi(ratingAgencyApiModel) {
		const ratingAgencyUiModel = new RatingAgency()
		if (ratingAgencyApiModel === undefined || ratingAgencyApiModel.data === null) {
			return ratingAgencyUiModel
		}
		ratingAgencyUiModel.id = ratingAgencyApiModel.data.id
		ratingAgencyUiModel.title = ratingAgencyApiModel.data.attributes.title
		ratingAgencyUiModel.min = ratingAgencyApiModel.data.attributes.min
		ratingAgencyUiModel.max = ratingAgencyApiModel.data.attributes.max
		ratingAgencyUiModel.ratingType = ratingAgencyApiModel.data.attributes.ratingType
		ratingAgencyUiModel.mandatory = ratingAgencyApiModel.data.attributes.mandatory
		ratingAgencyUiModel.default = ratingAgencyApiModel.data.attributes.default
		return ratingAgencyUiModel
	}

}